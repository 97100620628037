import React from "react";
import {Trans, useTranslation, I18nextContext} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql} from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";
import {returnLocaleURL} from "../../../utils/utils";
import Solution from "../../../components/solutions/Solution";
import ElectronicLockLineup from "../../../components/solutions/ElectronicLockLineup";
import GatsbySlideshow from "../../../components/carousel/gatsby-slideshow/GatsbySlideshow";

import evidenceLockers from './images/applications/evidence.jpg';
import personalStorage from './images/applications/personal.jpg';
import staffLockers from './images/applications/staff-lockers.jpg';
import workplaceStorage from './images/applications/workplace-storage.jpg';
import CustomerVideo from "../../../components/solutions/CustomerVideo";
import posterSolution from "./images/poster-government.jpg";

const IndexPage = (data) => {

  const {t} = useTranslation();
  const context = React.useContext(I18nextContext);
  let sl = context.language;
  const d = data.data.Slides.edges;
  const images = [];
  for(let i= 0; i<= d.length-1; i++) {
    images.push(d[i].node.childImageSharp);
  }
  const projectAsides = [
    '<span>Belgium</span><br>' +
    '<h2>Police Station</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock Versa Standard RFID</a></p>',

    '<span>united states</span><br>' +
    '<h2>US General Services Administration</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/electronic-locks/sola/') + '">Digilock Sola Keypad</a></p>',

    '<span>switzerland</span><br>' +
    '<h2>Fire Station</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock 4G RFID</a></p>',

    '<span>the netherlands</span><br>' +
    '<h2>City Hall Amsterdam</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock Versa Mini RFID</a></p>',

    '<span>the netherlands</span><br>' +
    '<h2>Bouwend</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock 4G RFID</a></p>',

    '<span>the netherlands</span><br>' +
    '<h2>Municipal Offices De Rotterdam</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock Axis RFID</a></p>',

    '<span>france</span><br>' +
    '<h2>Le Louvre </h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock 4G Keypad</a></p>',
  ];
  const solutions = [
    {
      img: staffLockers,
      label: 'retail_locker_rooms',
      alt: 'staff_lockers',
      bg: 'light'
    },
    {
      img: evidenceLockers,
      label: 'evidence_lockers',
      alt: 'evidence_lockers',
      bg: 'dark'
    },
    {
      img: personalStorage,
      label: 'personal_storage',
      alt: 'personal_storage',
      bg: 'dark'
    },
    {
      img: workplaceStorage,
      label: 'workplace_storage',
      alt: 'workplace_storage',
      bg: 'light'
    }
  ]

  return (
      <Layout>
        <Seo title={t('solutions_government')}
             description={t('des_solutions_government')}/>
        <div className="solutions">
          <section className="hero --hero-image no-pad">
            <StaticImage
                src="../../../images/industries/hero-government.jpg"
                quality={100}
                layout={'fullWidth'}
                formats={["auto", "webp", "avif"]}
                alt="Government"
            />
            <h1>
              <Trans>
                government
              </Trans>
              <span>
                <Trans>
                  government_hero_subhead
                </Trans>
              </span>
            </h1>
          </section>
          <section className="customer-video healthcare">
            <div className="container">
              <div className={'grid-container'}>
                <h2><Trans>government_subhero_title</Trans></h2>
                <ul>
                  <li><Trans>government_subhero_list_l1</Trans></li>
                  <li><Trans>government_subhero_list_l2</Trans></li>
                  <li><Trans>government_subhero_list_l3</Trans></li>
                  <li><Trans>government_subhero_list_l4</Trans></li>
                  <li><Trans>government_subhero_list_l5</Trans></li>
                  <li><Trans>government_subhero_list_l6</Trans></li>
                </ul>
              </div>
              <div className="video-holder">
                <StaticImage
                    src="./images/government.jpg"
                    quality={100}
                    width={508}
                    height={312}
                    formats={["auto", "webp", "avif"]}
                    loading={'lazy'}
                    alt="government"
                />
              </div>
            </div>
          </section>
          <ElectronicLockLineup label={'government_lineup_label'} showMech={false} showPivot={true} showAspire={true} showVersa={true} showOrbit={true} showCTA={true}/>
          <section className="industry-carousel">
            <div className="container">
              <h2>
                <Trans>
                  government_carousel
                </Trans>
              </h2>
              <GatsbySlideshow images={images} overlays={projectAsides} />
            </div>
          </section>
          <section className="customer-video healthcare">
            <CustomerVideo
                poster={posterSolution}
                title={'government_customer_video_title'}
                copy={'government_customer_video_copy'}
                vidURL={'https://cdn.mediavalet.com/usva/digilock/uh4yKq0X402GbFqPAZil-g/meHMf5gLokWGjILOiZbTxQ/Widescreen720p/government.mp4'}
            />
          </section>
          <section className="industry-uses">
            <div className="container">
              <h2><Trans>popular_keyless</Trans></h2>
              <Solution items={solutions}/>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    Slides: allFile(
    filter: {relativeDirectory: {eq: "solutions/government"}}
    sort: {fields: [name], order: ASC}
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
      }
    }
  }
  
  }
`;
